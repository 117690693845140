// app.js

require('../css/app.css');

const $ = require('jquery');
global.$ = global.jQuery = $;

require('bootstrap');

$(document).ready(function() {
    $('[data-toggle="popover"]').popover();

    /*$('#sidebarCollapse').on('click', function () {
        $('#sidebar').toggleClass('active');
    });*/

    /*$("#sidebar").mCustomScrollbar({
        theme: "minimal"
    });*/

    $('#sidebarCollapse').on('click', function () {
        $('#sidebar').toggleClass('active');
        $('#content').toggleClass('active');
        $(this).toggleClass('active');
    });

});

